<template>
<div>
<app-header />
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Register v1 -->
      <b-card class="mb-0">
        <!-- <b-link class="brand-logo">
          <vuexy-logo />
          <h2 class="brand-text text-primary" style="margin-top: 3px;">
            Hustleforge
          </h2>
        </b-link> -->

        <b-card-title class="mb-1">
          Welcome to Hustleforge!
        </b-card-title>
        <b-card-text class="mb-2">
          Thanks for becoming a member.
        </b-card-text>

        <!-- form -->
        <validation-observer ref="registerForm">
          <b-form
            class="auth-register-form mt-2"
            @submit.prevent="register"
          >
            <!-- username -->
            <b-form-group
              label="Username"
              label-for="Username"
            >
              <validation-provider
                #default="{ errors }"
                name="username"
                rules="required"
              >
                <b-form-input
                  id="username"
                  v-model="username"
                  :state="errors.length > 0 ? false:null"
                  name="register-username"
                  placeholder="john-doe"
                />
                <small class="text-primary">*Note: Username cannot be changed after account is created!</small>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group
              label="Password"
              label-for="password"
            >
            
              <validation-provider
                #default="{ errors }"
                name="password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="register-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="privacy-policy"
                rules="required"
              >
              <b-form-checkbox
                id="register-privacy-policy"
                v-model="status"
                :state="errors.length > 0 ? false:null"
                name="checkbox-1"
              >
                I agree to
                <b-link class="pr-0.5" :to="{name:'privacy-policy'}">Privacy Policy</b-link> & 
                <b-link class="pl-0.5" :to="{name:'public-terms'}">Terms and Conditions</b-link>
              </b-form-checkbox>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-overlay
              :show="busy"
              rounded
              opacity="0.4"
              spinner-small
              spinner-variant="primary"
            >
              <b-button
                variant="primary"
                block
                type="submit"
              >
                Finalize Account
              </b-button>
            </b-overlay>
          </b-form>
        </validation-observer>

      </b-card>
    <!-- /Register v1 -->
    </div>
  </div>
  <app-footer />
</div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BLink, BCardTitle, BCardText, BForm,
  BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend, BFormCheckbox, BOverlay
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import md5  from 'md5'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { getAuth, signInWithEmailAndPassword, sendEmailVerification} from "firebase/auth";
import { getFirestore, doc, getDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import { getFunctions, httpsCallable } from "firebase/functions"
import { getToken, isSupported} from "firebase/messaging"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppFooter from '../landing/AppFooter.vue'
import AppHeader from '../landing/AppHeader.vue'

const auth = getAuth()
const db = getFirestore()
const functions = getFunctions()

export default {
  components: {
    VuexyLogo,
    // BSV
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BOverlay,
    // validations
    ValidationProvider,
    ValidationObserver,
    AppFooter,
    AppHeader,
    md5,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      regEmail: '',
      fullname: '',
      username: '',
      password: '',
      status: '',
      code: '',
      busy: false,

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted: async function() { 
    if(!this.$route.query || !this.$route.query.session_id){
      this.$router.push('/pricing')
    } 

    // Check to make sure they dont already have an accout
    const profileRes = await getDoc(doc(db, "profiles", auth.currentUser.displayName))
    if(profileRes.exists()){
      this.busy = true
      const createSubscriptionUser = httpsCallable(functions, 'createSubscriptionUser')
      const response = await createSubscriptionUser({ username: auth.currentUser.displayName, sessionID: this.$route.query.session_id })
      if(response && response.data.success){
        this.$router.push('/app/search')
      }
      this.busy = false
    }
  },
  methods: {
    notifcationSupported(){
      return 'Notification' in window &&
      'serviceWorker' in navigator &&
      'PushManager' in window
    },
    async register() {
      // Validate form for to make sure data is correct
      const validateForm = await this.$refs.registerForm.validate()
      if (!validateForm) return
        
      // Check username to make sure it is unique
      const checkUserNames = await getDoc(doc(db, "usernames", this.username))
      if (checkUserNames.exists()){
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Signup Failed`,
              icon: 'user-x',
              variant: 'danger',
              text: 'Username already exists',
            },
          })
          return
      }

      try {

        this.busy = true

        // Register the new user 
        const createSubscriptionUser = httpsCallable(functions, 'createSubscriptionUser')
        const response = await createSubscriptionUser({ username: this.username.trim(), password: this.password, sessionID: this.$route.query.session_id })
        if(response && response.data.success){
          
          const userCredential = await signInWithEmailAndPassword(auth, response.data.email, this.password)
          const user = userCredential.user;

          // Did we have a verified email
          if(!user.emailVerified){

            // Send email verification
            sendEmailVerification(user)
          }

          // Make sure the user has a profile already
          const profileRes = await getDoc(doc(db, "profiles", user.displayName))
          if (!profileRes.exists()){
              this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Login Failed`,
                icon: 'user-x',
                variant: 'danger',
                text: 'Could not find profile for you account. Please connact support@hustleforge.com',
              },timeout: 5000
            })
            this.busy = false
            return
          }

          // Build userData and store locally
          let profile = profileRes.data()
          profile.email = user.email
          profile.profileComplete = profile.profileComplete
          profile.role = "admin" // Hardcode this for now
          profile.ability = []
          profile.ability.push({action: "manage", subject: "all"}) // Hardcode this for now

          localStorage.setItem('userData', JSON.stringify(profile))
          this.$ability.update(profile.ability) // TODO Change this later

          // ASk for a push token
          if (isSupported() && this.notifcationSupported()) {
            const permission = await Notification.requestPermission()
            if (permission === 'granted'){
              await getToken(this.$messaging, { vapidKey: 'BJluftTUTm76uZqpL8BhlG0I_d25Hos_qxZhciubU-3MAWbcjrbnd-pRgf3WFB_NQIO7B5YLbgmE1SxfNUYHeck' }).then((currentToken) => {
                if (currentToken) {
                    profile.pushToken = currentToken
                    updateDoc(doc(db, "profiles", user.displayName ), { pushTokens: arrayUnion(currentToken)})
                } else {
                  console.log('No registration token available. Request permission to generate one.')
                }
              })
            }
          }

        // Send to dashboard
        const route = (profile.profileComplete)?getHomeRouteForLoggedInUser(profile.role):'/app/onboarding'
        this.busy = false
        this.$router.replace(route).then(() => {
          
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Welcome ${profile.fullName}`,
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
  
        }else{
            this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Signup Failed`,
              icon: 'user-x',
              variant: 'danger',
              text: response.message,
            },timeout: 5000
          })
          this.busy = false
        }
  
      }catch(error){
        console.log(error)
        let errorMessage = error;
        if(error.code) errorMessage = error.code.replace("auth/","").replaceAll("-", " ");
        this.busy = false

        // Show error
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Signup Failed`,
            icon: 'user-x',
            variant: 'danger',
            text: errorMessage,
          },timeout: 5000
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
