import { render, staticRenderFns } from "./SubscriptionRegister.vue?vue&type=template&id=cdbd801a"
import script from "./SubscriptionRegister.vue?vue&type=script&lang=js"
export * from "./SubscriptionRegister.vue?vue&type=script&lang=js"
import style0 from "./SubscriptionRegister.vue?vue&type=style&index=0&id=cdbd801a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports